/* eslint-disable react/display-name */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import { theme } from '../../styles'
import Text from '../common/Text'
import toKebabCase from '../../utils/toKebabCase'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Label = styled.label`
  p {
    font-family: ${p => (p.labelFont ? theme.fonts.family[p.labelFont] : theme.fonts.family.smallBodyMedium)};
    font-weight: ${p => (p.labelFont ? theme.fonts.weight[p.labelFont] : theme.fonts.weight.smallBodyMedium)};
    font-size: ${p => (p.labelFont ? theme.fonts.size[p.labelFont] : theme.fonts.size.smallBodyMedium)};
  }
`

const Select = styled.select`
  width: 100%;
  background-color: ${p => (p.darkBG ? 'transparent' : theme.color.white)};
  font-size: ${theme.fonts.size.smallBody};
  font-weight: ${theme.fonts.weight.smallBody};
  font-family: ${theme.fonts.family.smallBody};
  padding: 8px;
  border-radius: 8px;
  border: 1px solid;
  border-color: ${p => (p.darkBG ? theme.color.white : theme.color.gray300)};
  color: ${p => (p.darkBG ? theme.color.white : theme.color.offBlack)};
  appearance: initial;

  ::placeholder {
    color: ${p => (p.darkBG ? theme.color.white : theme.color.gray700)};
    font-family: ${theme.fonts.family.smallBody};
  }

  &:focus {
    border: 1px solid;
    border-color: ${p => (p.darkBG ? theme.color.white : theme.color.gray300)}!important;

    + span.focused-border {
      width: auto;
      height: auto;
      border: 3px solid;
      border-color: ${p => (p.darkBG ? theme.color.green500 : theme.color.purple500)};
    }
  }

  @media (max-width: 800px) {
    margin-top: 0px;
  }
`

export const SelectWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: 8px;

  ::after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: ${props => props.theme.color.gray700} transparent transparent transparent;
    position: absolute;
    right: 10px;
    content: '';
    top: 18px;
    pointer-event: none;
  }
`

export const RequiredSpan = styled.span`
  display: inline-block;
  font-size: ${theme.fonts.size.smallBodyMedium};
  font-weight: ${theme.fonts.weight.smallBodyMedium};
  font-family: ${theme.fonts.family.smallBodyMedium};
  color: ${theme.color.red500};
`

const SelectComponent = forwardRef((props, ref) => {
  const { label, error, wrapperStyle, scanner, required, requiredLabel, options } = props
  return (
    <Wrapper label={label} style={wrapperStyle} className="select-component-wrapper">
      {label ? (
        <Label className="select-component-label" labelFont={props.labelFont ? props.labelFont : 'smallBodyMedium'}>
          <Text>
            {label} {required && !scanner && <RequiredSpan>*</RequiredSpan>}
          </Text>
          <SelectWrapper className="select-component-wrapper">
            <Select
              ref={ref || null}
              {...props}
              required={required}
              className={`select-component-select ${(label && toKebabCase(label)) || null} ${props.className || null}`}
              onInput={e => e.target.setCustomValidity(``)}
              onInvalid={e => {
                if (e.target.validity.valueMissing) {
                  e.target.setCustomValidity(`Please enter a valid ${label.toLowerCase()}`)
                }
              }}
              defaultValue=""
            >
              <option disabled value="">
                Please Select
              </option>
              {options.map(currentOption => (
                <option key={currentOption.value} value={currentOption.value}>
                  {currentOption.label}
                </option>
              ))}
            </Select>
          </SelectWrapper>
        </Label>
      ) : (
        <SelectWrapper>
          <Select
            {...props}
            ref={ref || null}
            className={`select-component-input ${(label && toKebabCase(label)) || null} ${props.className || null}`}
            onInput={e => e.target.setCustomValidity(``)}
            onInvalid={e => {
              if (e.target.validity.valueMissing && requiredLabel) {
                e.target.setCustomValidity(`Please enter a valid ${requiredLabel.toLowerCase()}`)
              }
            }}
            defaultValue=""
          >
            <option disabled value="">
              Please Select
            </option>
            {options.map(currentOption => (
              <option key={currentOption.value} value={currentOption.value}>
                {currentOption.label}
              </option>
            ))}
          </Select>
        </SelectWrapper>
      )}

      <Text tinyBody color="red500">
        {error}
      </Text>
    </Wrapper>
  )
})

export default SelectComponent
