import React from 'react'
import TextLink from '../components/common/TextLink'

const generateOnboardingFormTranslations = ({ userLang }) => {
  const data = {
    en: {
      heading: 'Start Free 14-Day Trial',
      requiredField: 'Required',
      fullNameField: 'Full Name',
      workEmailField: 'Work Email',
      educationRoleLabel: 'Education Role',
      phoneNumberField: 'Phone Number',
      companyField: 'Company',
      websiteUrlField: 'Website URL',
      passwordField: 'Password',
      passwordHelperText: '8+ characters, with a number, lower and uppercase letter',
      submitButtonText: 'Create Account',
      legalText: (
        <>
          By creating an account, you're agreeing to our{' '}
          <TextLink
            to="/terms-of-service/"
            href="/terms-of-service/"
            text="Terms of Service"
            color="purple500"
            font="tinyBody"
          />{' '}
          and{' '}
          <TextLink to="/privacy-policy/" href="/privacy-policy/" text="Policies" color="purple500" font="tinyBody" />
        </>
      ),
      legalTextPartners: (
        <>
          By creating an account, you're agreeing to our{' '}
          <TextLink
            to="/reseller-program-agreement/"
            href="/reseller-program-agreement/"
            text="Reseller Terms and Conditions"
            color="purple500"
            font="tinyBody"
          />
          {', '}
          <TextLink
            to="/terms-of-service/"
            href="/terms-of-service/"
            text="Terms of Service"
            color="purple500"
            font="tinyBody"
          />
          , and{' '}
          <TextLink to="/privacy-policy/" href="/privacy-policy/" text="Policies" color="purple500" font="tinyBody" />
        </>
      ),
    },
    de: {
      heading: 'Kostenlose 14-tägige Testphase starten',
      requiredField: 'erforderlich',
      fullNameField: 'Vollständiger Name',
      workEmailField: 'Arbeits-E-Mail',
      educationRoleLabel: 'Bildungsrolle',
      phoneNumberField: 'Telefonnummer',
      companyField: 'Unternehmen',
      websiteUrlField: 'Webadresse',
      passwordField: 'Passwort',
      passwordHelperText: '>8 Zeichen lang, mit einer Ziffer und Klein- und Großbuchstaben',
      submitButtonText: 'Benutzerkonto erstellen',
      legalText: (
        <>
          Indem Sie ein Konto erstellen sie erklären sich mit unseren{' '}
          <TextLink
            to="/de/terms-of-service-de/"
            href="/de/terms-of-service-de/"
            text="Nutzungsbedingungen"
            color="purple500"
            font="tinyBody"
          />{' '}
          und{' '}
          <TextLink
            to="/de/privacy-policy-de/"
            href="/de/privacy-policy-de/"
            text="Datenrichtlinien einverstanden"
            color="purple500"
            font="tinyBody"
          />
        </>
      ),
      legalTextPartners: (
        <>
          Indem Sie ein Konto erstellen sie erklären sich mit unseren{' '}
          <TextLink
            to="/reseller-program-agreement/"
            href="/reseller-program-agreement/"
            text="Reseller-Nutzungsbedingungen"
            color="purple500"
            font="tinyBody"
          />
          {', '}
          <TextLink
            to="/de/terms-of-service-de/"
            href="/de/terms-of-service-de/"
            text="Nutzungsbedingungen"
            color="purple500"
            font="tinyBody"
          />
          , und{' '}
          <TextLink
            to="/de/privacy-policy-de/"
            href="/de/privacy-policy-de/"
            text="Datenrichtlinien einverstanden"
            color="purple500"
            font="tinyBody"
          />
        </>
      ),
      emailCommunications:
        'Da wir unser Unternehmen in Europa noch ausbauen, wird unser Vertriebsteam mit Ihnen auf Englisch kommunizieren. Vielen Dank für Ihre Geduld, während unser Unternehmen noch wächst.',
    },
    fr: {
      heading: "Commencez l'essai gratuit de 14 jours",
      requiredField: 'requis',
      fullNameField: 'Nom et prénom',
      workEmailField: 'Email de travail',
      educationRoleLabel: 'Rôle éducatif',
      phoneNumberField: 'Numéro de téléphone',
      companyField: 'entreprise',
      websiteUrlField: 'URL de site web',
      passwordField: 'Mot de passe',
      passwordHelperText: '8+ caractères, avec un chiffre, une lettre minuscule et majuscule',
      submitButtonText: 'Créer un compte',
      legalText: (
        <>
          En créant un compte, vous acceptez nos{' '}
          <TextLink
            to="/fr/terms-of-service-fr/"
            href="/fr/terms-of-service-fr/"
            text="nos conditions de service"
            color="purple500"
            font="tinyBody"
          />{' '}
          et{' '}
          <TextLink
            to="/fr/privacy-policy-fr/"
            href="/fr/privacy-policy-fr/"
            text="nos politiques en matière de données."
            color="purple500"
            font="tinyBody"
          />
        </>
      ),
      legalTextPartners: (
        <>
          En créant un compte, vous acceptez nos{' '}
          <TextLink
            to="/reseller-program-agreement/"
            href="/reseller-program-agreement/"
            text="conditions de revendeur"
            color="purple500"
            font="tinyBody"
          />
          {', '}
          <TextLink
            to="/fr/terms-of-service-fr/"
            href="/fr/terms-of-service-fr/"
            text="nos conditions de service"
            color="purple500"
            font="tinyBody"
          />
          , et{' '}
          <TextLink
            to="/fr/privacy-policy-fr/"
            href="/fr/privacy-policy-fr/"
            text="nos politiques en matière de données."
            color="purple500"
            font="tinyBody"
          />
        </>
      ),
      emailCommunications:
        'Étant donné que nous étendons notre entreprise en Europe, les communications de notre équipe commerciale se feront en anglais. Nous vous remercions de votre patience pendant que notre entreprise se développe.',
    },
    es: {
      heading: 'Comenzar prueba gratuita de 14 días',
      requiredField: 'obligatorio',
      fullNameField: 'Nombre completo',
      workEmailField: 'Correo electrónico empresarial',
      educationRoleLabel: 'Papel de la educación',
      phoneNumberField: 'Número de teléfono',
      companyField: 'Empresa',
      websiteUrlField: 'URL del sitio web',
      passwordField: 'Contraseña',
      passwordHelperText: '8+ caracteres, con un número, letra minúscula y mayúscula',
      submitButtonText: 'Crear una cuenta',
      legalText: (
        <>
          Al crear una cuenta, acepta nuestros
          <TextLink
            to="/es/terms-of-service-es/"
            href="/es/terms-of-service-es/"
            text="términos de servicio"
            color="purple500"
            font="tinyBody"
          />{' '}
          y{' '}
          <TextLink
            to="/es/privacy-policy-es/"
            href="/es/privacy-policy-es/"
            text="políticas de datos"
            color="purple500"
            font="tinyBody"
          />
          .
        </>
      ),
      legalTextPartners: (
        <>
          Al crear una cuenta, acepta nuestros{' '}
          <TextLink
            to="/reseller-program-agreement/"
            href="/reseller-program-agreement/"
            text="Términos y condiciones de revendedor"
            color="purple500"
            font="tinyBody"
          />
          {', '}
          <TextLink
            to="/es/terms-of-service-es/"
            href="/es/terms-of-service-es/"
            text="Términos de servicio"
            color="purple500"
            font="tinyBody"
          />
          , y{' '}
          <TextLink
            to="/es/privacy-policy-es/"
            href="/es/privacy-policy-es/"
            text="Políticas de datos"
            color="purple500"
            font="tinyBody"
          />
          .
        </>
      ),
    },
    it: {
      heading: 'Avvia la prova gratuita di 14 giorni',
      requiredField: 'richiesto',
      fullNameField: 'Nome completo',
      workEmailField: 'E-mail di lavoro',
      educationRoleLabel: 'Ruolo educativo',
      phoneNumberField: 'Numero di telefono',
      companyField: 'azienda',
      websiteUrlField: 'URL sito web',
      passwordField: 'Password',
      passwordHelperText: '8+ caratteri tra cui un numero, una lettera minuscola e una maiuscola',
      submitButtonText: 'Crea account',
      legalText: (
        <>
          Creando un account, accetti i nostri{' '}
          <TextLink
            to="/it/terms-of-service-it/"
            href="/it/terms-of-service-it/"
            text="termini di servizio"
            color="purple500"
            font="tinyBody"
          />{' '}
          e{' '}
          <TextLink
            to="/it/privacy-policy-it/"
            href="/it/privacy-policy-it/"
            text="le nostre politiche sui dati"
            color="purple500"
            font="tinyBody"
          />
          .
        </>
      ),
      legalTextPartners: (
        <>
          Creando un account, accetti i nostri{' '}
          <TextLink
            to="/reseller-program-agreement/"
            href="/reseller-program-agreement/"
            text="Termini e condizioni per rivenditori"
            color="purple500"
            font="tinyBody"
          />
          {', '}
          <TextLink
            to="/it/terms-of-service-it/"
            href="/it/terms-of-service-it/"
            text="Termini di servizio"
            color="purple500"
            font="tinyBody"
          />
          , e{' '}
          <TextLink
            to="/it/privacy-policy-it/"
            href="/it/privacy-policy-it/"
            text="Norme sui dati"
            color="purple500"
            font="tinyBody"
          />
          .
        </>
      ),
    },
  }

  return data[userLang] || data.en
}

export default generateOnboardingFormTranslations
