/* eslint-disable camelcase */
import React from 'react'
import styled from '@emotion/styled'
import kebabCase from 'lodash/kebabCase'

import { theme } from '../../styles'
import grayArrowIcon from '../../../static/icons/arrow-icons/gray-left-arrow-icon.svg'
import Text from '../common/Text'
import Space from '../common/Space'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% + 300px);
  margin-left: -150px;
  padding: 80px 0px;
  max-width: 100vw;

  @media (max-width: 1500px) {
    padding: 80px 24px;
  }

  @media (max-width: 1050px) {
    width: calc(100% + 20px);
    max-width: unset;
    margin-left: 0px;
    overflow-x: scroll;
    align-items: flex-start;
    padding: 80px 24px 0px 0px;
    margin-bottom: 80px;
  }
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 1050px) {
    overflow-x: scroll;
    width: max-content;
  }
`

const ColumnHeader = styled.div`
  display: flex;
  justify-content: center;
  flex: 3;
  border: 2px solid ${p => p.theme.color[p.borderColor]};
  width: 100%;
  padding: 16px;
  border-radius: 8px;

  @media (max-width: 1050px) {
    max-width: 300px;
  }

  @media (max-width: 800px) {
    max-width: 225px;
  }

  p {
    text-align: left;
  }
`

const RowGroup = styled.div``

const Row = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  border: 1px solid ${theme.color.gray200};
  border-bottom: none;
  background-color: ${p => p.theme.color[p.backgroundColor]};

  &:nth-of-type(1) {
    border-radius: 16px 16px 0px 0px;
  }

  div:first-of-type {
    flex: 2;
    padding-left: 16px;

    @media (max-width: 1050px) {
      max-width: 225px;
    }
  }

  &:last-of-type {
    border-bottom: 1px solid ${theme.color.gray200};
    border-radius: 0px 0px 16px 16px;
  }
`

const HeadingRow = styled(Row)`
  margin-bottom: 24px;
  border-color: transparent;
  padding-left: 0px;

  &:last-of-type {
    border-bottom: none;
  }
`

const TableCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Block = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 3;
  padding: 16px 0px;

  @media (max-width: 1050px) {
    width: 50vw;
    max-width: 300px;
  }

  @media (max-width: 800px) {
    max-width: 225px;
    width: 80vw;
  }
`

const HeadingBlock = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  padding-right: 16px;

  @media (max-width: 1050px) {
    display: none;
  }
`

const MobHeadingBlock = styled.div`
  display: none;

  @media (max-width: 1050px) {
    display: flex;
    margin-bottom: 24px;
  }
`

const ArrowIcon = styled.img`
  margin-left: 16px;
`

const MobileSwipeTextContainer = styled.div`
  display: none;

  @media (max-width: 1050px) {
    max-width: 220px;
    display: flex;
    padding-right: 16px;
    align-items: center;
    justify-content: center;
  }
`

const sections = [
  {
    items: [
      {
        column1: 'Options',
        column2: 'Hybrid solution combining in-house expertise and audits with industry-leading automation',
        column3: 'Primarily automation, with outsourced manual audits',
      },
      {
        column1: 'Manual Audits',
        column2:
          'In-depth manual audits, with custom-coded fixes or prescriptive guidance for internal development teams',
        column3: 'Cursory manual audits, with no issue remediation and generic observations delivered via spreadsheet',
      },
      {
        column1: 'Scalability',
        column2: 'Supports large, complex, enterprise websites with dynamic content and custom functionality',
        column3: 'Out-of-the-box solution that only supports small websites built on simple platforms',
      },
      {
        column1: 'Lawsuit Prevention',
        column2: 'AudioEye customers are 4x less likely to receive a valid legal claim',
        column3: '35% of legal claims deemed to be valid after AudioEye’s analysis',
      },
      {
        column1: 'Litigation Support',
        column2:
          'Dedicated legal team will meet with you and provide documentation to help invalidate nuisance claims.',
        column3: 'Litigation support is limited to email communication with support staff',
      },
      {
        column1: 'Legal Guarantee',
        column2: 'Custom legal response, industry-best payout, and ongoing support',
        column3: 'Legal guarantee only paid out to customers who lose a lawsuit, but accessiBe settles every lawsuit',
      },
      {
        column1: 'Automation',
        column2: 'Continuously finds and fixes violations including dynamic and subjective content',
        column3: 'Runs once every 24 hours but has limited reach, unable to scan content behind logins or paywalls',
      },
      {
        column1: 'Depth of Coverage',
        column2: 'Every single page a user visits is covered with AudioEye’s accessibility solution',
        column3: '35% of most pages will have coverage, leaving the rest of your pages inaccessible',
      },
      {
        column1: 'End-User Support',
        column2: '24/7 support for customers with disabilities who cannot access something on your website',
        column3: 'No support, leaving you to handle every support ticket from customers with disabilities',
      },
      {
        column1: 'Patents',
        column2: 'First to innovate, holding 20+ U.S. Patents',
        column3: 'No patents',
      },
    ],
  },
]

const Section = ({ title, items }) => (
  <>
    {items.map(
      (
        item,
        i // eslint-disable-next-line react/no-array-index-key
      ) => (
        // eslint-disable-next-line react/no-array-index-key
        <Row role="row" key={`$TITLE-${i}`} backgroundColor={i % 2 ? 'white' : 'gray50'}>
          <Block role="cell" aria-labelledby={`table-header-${kebabCase(title)}`}>
            <Text smallBodyMedium>{item.column1}</Text>
          </Block>
          <Block role="cell" aria-labelledby={`table-header-${kebabCase(title)}`}>
            <Text smallBodyMedium>{item.column2}</Text>
          </Block>
          <Block role="cell" aria-labelledby={`table-header-${kebabCase(title)}`}>
            <Text smallBodyMedium color="gray500">
              {item.column3}
            </Text>
          </Block>
        </Row>
      )
    )}
  </>
)

const AccessibeComparison = () => (
  <Wrapper>
    <Text largeCaps color="purple500">
      THE AUDIOEYE ADVANTAGE
    </Text>
    <Space height={24} />
    <Text heading2>Why do teams switch from accessiBe to AudioEye?</Text>
    <Space height={80} />
    <TableWrapper role="table">
      <MobHeadingBlock>
        <Text heading3>Comparison</Text>
      </MobHeadingBlock>
      <div role="rowgroup" style={{ zIndex: '1' }}>
        <HeadingRow role="row">
          <HeadingBlock role="cell">
            <Text heading3>Comparison</Text>
          </HeadingBlock>
          <MobileSwipeTextContainer>
            <ArrowIcon src={grayArrowIcon} style={{ marginRight: '16px' }} />
            <Text smallBody color="gray700">
              Swipe to see and compare
            </Text>
          </MobileSwipeTextContainer>
          <ColumnHeader borderColor="purple500">
            <Text bodyBold center role="columnheader">
              AudioEye
            </Text>
          </ColumnHeader>
          <ColumnHeader borderColor="gray300">
            <Text bodyBold center role="columnheader">
              accessiBe
            </Text>
          </ColumnHeader>
        </HeadingRow>
      </div>
      <TableCard>
        {sections.map(currentSection => (
          <RowGroup role="rowgroup" key={kebabCase(currentSection.column1)}>
            <Section title={currentSection.column1} items={currentSection.items} />
          </RowGroup>
        ))}
      </TableCard>
    </TableWrapper>
    <Space height={40} />
    <Text color="gray500">
      <i>
        Competitive data on this page was collected as of November 1, 2023 and is subject to change or update. AudioEye
        does not make any representations as to the completeness or accuracy of the information on this page.
      </i>
    </Text>
  </Wrapper>
)

export default AccessibeComparison
