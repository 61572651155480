/* eslint-disable camelcase */
import React from 'react'
import styled from '@emotion/styled'
import kebabCase from 'lodash/kebabCase'

import { theme } from '../../styles'
import grayArrowIcon from '../../../static/icons/arrow-icons/gray-left-arrow-icon.svg'
import Text from '../common/Text'
import TextLink from '../common/TextLink'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 0px 80px 0px;
  max-width: 100vw;

  @media (max-width: 1050px) {
    width: calc(100% + 20px);
    max-width: unset;
    margin-left: 0px;
    overflow-x: scroll;
    align-items: flex-start;
    padding: 80px 24px 0px 0px;
    margin-bottom: 80px;
  }
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 800px) {
    overflow-x: scroll;
    width: max-content;
    min-width: 200px;
  }
`

const ColumnHeader = styled.div`
  display: flex;
  justify-content: center;
  flex: 3;
  border: 2px solid ${p => p.theme.color[p.borderColor]};
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  height: 80px;
  align-items: center;

  p {
    text-align: left;
  }
`

const RowGroup = styled.div``

const Row = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  border: 1px solid ${theme.color.gray200};
  border-bottom: none;
  background-color: ${p => p.theme.color[p.backgroundColor]};

  &:nth-of-type(1) {
    border-radius: 16px 16px 0px 0px;
  }

  div:nth-of-type(1) {
    padding-left: 24px;
    flex: 2;
  }

  div:nth-of-type(2) {
    flex: 3;
  }

  div:nth-of-type(3) {
    flex: 3;
  }

  div:nth-of-type(4) {
    flex: 3;
  }

  &:last-of-type {
    border-bottom: 1px solid ${theme.color.gray200};
    border-radius: 0px 0px 16px 16px;
    text-align: center;
    display: flex;
    align-items: center;
    width: 100%;

    input {
      width: 100%;
    }
  }
`

const HeadingRow = styled(Row)`
  margin-bottom: 24px;
  border-color: transparent;
  padding-left: 0px;

  div:nth-of-type(2) {
    flex: 2;
  }

  div:nth-of-type(3) {
    flex: 3;
  }

  div:nth-of-type(4) {
    flex: 3;
  }

  div:nth-of-type(5) {
    flex: 3;
  }

  &:last-of-type {
    border-bottom: none;
  }
`

const TableCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Block = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 6;
  padding: 16px 0px;
  width: 100%;
`

const MobHeadingBlock = styled.div`
  display: none;
`

const ArrowIcon = styled.img`
  margin-left: 16px;
`

const MobileSwipeTextContainer = styled.div`
  display: none;
`

const Section = ({ items }) => (
  <>
    {items.map(
      (
        item,
        i // eslint-disable-next-line react/no-array-index-key
      ) => (
        // eslint-disable-next-line react/no-array-index-key
        <Row role="row" key={`$TITLE-${i}`} backgroundColor={i % 2 ? 'white' : 'gray50'}>
          <Block role="rowheader" aria-labelledby={`table-header-${kebabCase(item.column1)}`}>
            <Text smallBodyMedium>
              <strong>{item.column1}</strong>
            </Text>
          </Block>
          <Block role="cell" aria-labelledby={`table-header-${kebabCase(item.column1)}`}>
            <Text smallBodyMedium>{item.column2}</Text>
          </Block>
          <Block role="cell" aria-labelledby={`table-header-${kebabCase(item.column1)}`}>
            <Text smallBodyMedium>{item.column3}</Text>
          </Block>
        </Row>
      )
    )}
  </>
)

const EAAVsWCAG = () => {
  const title = 'WCAG vs. the EAA: How are They Different?'

  const sections = [
    {
      items: [
        {
          column1: 'Type of Standard',
          column2: 'Legislative directive',
          column3: 'Technical guidelines',
        },
        {
          column1: 'Geographic Scope',
          column2: 'European Union member states',
          column3: 'Global standard',
        },
        {
          column1: 'Purpose',
          column2:
            'Ensure products and services within the EU are accessible to all, particularly those with disabilities',
          column3: 'Provide a framework for creating accessible digital content',
        },
        {
          column1: 'Legal Requirement',
          column2: 'Mandatory for businesses in scope',
          column3: (
            <>
              Not legally binding; used as a framework for accessibility laws, including{' '}
              <TextLink
                to="https://www.ada.gov/"
                target="_blank"
                font="smallBody"
                text="the Americans with Disabilities Act"
              />{' '}
              (ADA) and the EAA
            </>
          ),
        },
        {
          column1: 'Enforcement',
          column2: 'Enforced by EU member states through penalties and fines',
          column3: 'No direct enforcement',
        },
        {
          column1: 'Industries Covered',
          column2: 'Broad range, including eCommerce, banking, transportation, ICT, etc.',
          column3: 'Focuses on web content and digital platforms',
        },
        {
          column1: 'Accessibility Areas',
          column2: 'Physical and digital spaces',
          column3: 'Digital spaces',
        },
        {
          column1: 'Compliance Deadline',
          column2: 'June 28, 2025',
          column3: 'No specific deadline',
        },
        {
          column1: 'Focus on Universal Design',
          column2: 'Strong emphasis on universal design principles',
          column3: 'Encourages universal design but focuses on web content',
        },
        {
          column1: 'Level of Detail',
          column2: 'Broad and high-level, leaves specific requirements to member states',
          column3: 'Detailed technical criteria',
        },
        {
          column1: 'Audience',
          column2: 'Business, service providers, and policymakers',
          column3: 'Web developers, designers, copywriters, and accessibility professionals',
        },
        {
          column1: 'Updates',
          column2: 'Rarely updated; relies on EU legislative processes',
          column3: (
            <>
              Updated periodically (e.g., WCAG 2.1,{' '}
              <TextLink to="/compliance/wcag/" target="_blank" font="smallBody" text="WCAG 2.2" />)
            </>
          ),
        },
      ],
    },
  ]

  return (
    <Wrapper>
      <TableWrapper role="table" aria-label={title}>
        <MobHeadingBlock>
          <Text heading3>Comparison</Text>
        </MobHeadingBlock>
        <div role="rowgroup" style={{ zIndex: '1' }}>
          <HeadingRow role="row">
            <MobileSwipeTextContainer>
              <ArrowIcon src={grayArrowIcon} style={{ marginRight: '16px' }} />
              <Text smallBody color="gray700">
                Swipe to see more
              </Text>
            </MobileSwipeTextContainer>
            <ColumnHeader borderColor="gray300">
              <Text bodyBold center role="columnheader">
                Criteria
              </Text>
            </ColumnHeader>
            <ColumnHeader borderColor="gray300">
              <Text bodyBold center role="columnheader">
                EAA
              </Text>
            </ColumnHeader>
            <ColumnHeader borderColor="gray300">
              <Text bodyBold center role="columnheader">
                WCAG
              </Text>
            </ColumnHeader>
          </HeadingRow>
        </div>
        <TableCard>
          {sections.map(currentSection => (
            <RowGroup role="rowgroup" key={kebabCase(currentSection.column1)}>
              <Section title={currentSection.column1} items={currentSection.items} />
            </RowGroup>
          ))}
        </TableCard>
      </TableWrapper>
    </Wrapper>
  )
}

export default EAAVsWCAG
