const generateScannerGateTranslations = ({ userLang }) => {
  const data = {
    en: {
      eyebrow: 'See your full scan results',
      fullNameLabel: 'Full Name',
      businessEmailLabel: 'Business Email',
      educationRoleLabel: 'Education Role',
      submitButtonText: 'View Results',
    },
    de: {
      eyebrow: 'Vollständige Scan-Ergebnisse ansehen',
      fullNameLabel: 'Vollständiger Name',
      businessEmailLabel: 'Arbeits E-Mail',
      educationRoleLabel: 'Bildungsrolle',
      submitButtonText: 'Ergebnisse anzeigen',
      emailCommunications:
        'Da wir unser Unternehmen in Europa noch ausbauen, wird unser Vertriebsteam mit Ihnen auf Englisch kommunizieren. Vielen Dank für Ihre Geduld, während unser Unternehmen noch wächst.',
    },
    fr: {
      eyebrow: "Consultez les résultats complets de l'analyse",
      fullNameLabel: 'Nom et prénom',
      businessEmailLabel: 'E-mail de travail',
      educationRoleLabel: 'Rôle éducatif',
      submitButtonText: 'Voir les résultats',
      emailCommunications:
        'Étant donné que nous étendons notre entreprise en Europe, les communications de notre équipe commerciale se feront en anglais. Nous vous remercions de votre patience pendant que notre entreprise se développe.',
    },
    es: {
      eyebrow: 'Vea los resultados completos del análisis',
      fullNameLabel: 'Nombre completo',
      businessEmailLabel: 'Correo electrónico empresarial',
      educationRoleLabel: 'Papel de la educación',
      submitButtonText: 'Ver resultados',
    },
    it: {
      eyebrow: 'Visualizza i risultati completi della scansione',
      fullNameLabel: 'Nome completo',
      businessEmailLabel: 'E-mail di lavoro',
      educationRoleLabel: 'Ruolo educativo',
      submitButtonText: 'Visualizza i risultati',
    },
  }

  return data[userLang] || data.en
}

export default generateScannerGateTranslations
