import React from 'react'
import { graphql } from 'gatsby'
import EAAVsWCAG from './EAAVsWCAG'
import ADASection508WCAG from './ADASection508WCAG'
import ProductProcess from './ProductProcess'
import GreenhouseJobBoard from './GreenhouseJobBoard'
import PressReleasesGrid from './press/PressReleasesGrid'
import NewsItemsGrid from './press/NewsItemsGrid'
import ContrastChecker from '../ContrastChecker'
import InvestorRelations from './InvestorRelations'
import AccessibilityStatement from './AccessibilityStatement'
import CompetitorComparison from './CompetitorComparison'
import AccessibeComparison from './AccessibeComparison'
import UserWayComparison from './UserWayComparison'
import WCAGChecklistA from './WCAGChecklistA'
import WCAGChecklistAA from './WCAGChecklistAA'
import WCAGChecklistAAA from './WCAGChecklistAAA'
import QuickAuditChecklist from './QuickAuditChecklist'
import SEOContentAuditChecklist from './SEOContentAuditChecklist'
import UserExperienceAndDesignAuditChecklist from './UserExperienceAndDesignAuditChecklist'
import WebsiteAccessibilityAuditChecklist from './WebsiteAccessibilityAuditChecklist'
import WebsiteCROAuditChecklist from './WebsiteCROAuditChecklist'

export default function StaticContentBlock({ slice: { primary } }) {
  const EAA_VS_WCAG = 'eaa-vs-wcag'
  const ADA_SECTION508_WCAG = 'ada-section508-wcag'
  const PRODUCT_PROCESS = 'product-process'
  const GREENHOUSE_BOARD = 'greenhouse-board'
  const PRESS_RELEASES = 'press-releases'
  const IN_THE_NEWS = 'in-the-news'
  const CONTRAST_CHECKER = 'contrast-checker'
  const INVESTOR_RELATIONS = 'investor-relations'
  const ACCESSIBILITY_STATEMENT = 'accessibility-statement'
  const COMPETITOR_COMPARISON = 'competitor-comparison'
  const ACCESSIBE_COMPARISON = 'accessibe-comparison'
  const USERWAY_COMPARISON = 'userway-comparison'
  const WCAG_CHECKLIST_A = 'wcag-checklist-a'
  const WCAG_CHECKLIST_AA = 'wcag-checklist-aa'
  const WCAG_CHECKLIST_AAA = 'wcag-checklist-aaa'
  const QUICK_AUDIT_CHECKLIST = 'quick-audit-checklist'
  const SEO_CONTENT_AUDIT_CHECKLIST = 'seo-content-audit-checklist'
  const USER_EXPERIENCE_AND_DESIGN_AUDIT_CHECKLIST = 'user-experience-and-design-audit-checklist'
  const WEBSITE_ACCESSIBILITY_AUDIT_CHECKLIST = 'website-accessibility-audit-checklist'
  const WEBSITE_CRO_AUDIT_CHECKLIST = 'website-cro-audit-checklist'

  switch (primary.component_type) {
    case EAA_VS_WCAG:
      return <EAAVsWCAG />
    case ADA_SECTION508_WCAG:
      return <ADASection508WCAG />
    case PRODUCT_PROCESS:
      return <ProductProcess />
    case GREENHOUSE_BOARD:
      return <GreenhouseJobBoard />
    case PRESS_RELEASES:
      return <PressReleasesGrid />
    case IN_THE_NEWS:
      return <NewsItemsGrid />
    case CONTRAST_CHECKER:
      return <ContrastChecker />
    case INVESTOR_RELATIONS:
      return <InvestorRelations />
    case ACCESSIBILITY_STATEMENT:
      return <AccessibilityStatement />
    case COMPETITOR_COMPARISON:
      return <CompetitorComparison />
    case ACCESSIBE_COMPARISON:
      return <AccessibeComparison />
    case USERWAY_COMPARISON:
      return <UserWayComparison />
    case WCAG_CHECKLIST_A:
      return <WCAGChecklistA />
    case WCAG_CHECKLIST_AA:
      return <WCAGChecklistAA />
    case WCAG_CHECKLIST_AAA:
      return <WCAGChecklistAAA />
    case QUICK_AUDIT_CHECKLIST:
      return <QuickAuditChecklist />
    case SEO_CONTENT_AUDIT_CHECKLIST:
      return <SEOContentAuditChecklist />
    case USER_EXPERIENCE_AND_DESIGN_AUDIT_CHECKLIST:
      return <UserExperienceAndDesignAuditChecklist />
    case WEBSITE_ACCESSIBILITY_AUDIT_CHECKLIST:
      return <WebsiteAccessibilityAuditChecklist />
    case WEBSITE_CRO_AUDIT_CHECKLIST:
      return <WebsiteCROAuditChecklist />
    default:
      return null
  }
}

export const query = graphql`
  fragment StaticContentBlock on PrismicPageDataBodyStaticComponent {
    ...SliceBase
    primary {
      component_type
    }
  }

  fragment PostStaticContentBlock on PrismicPostDataBodyStaticComponent {
    ...SliceBase
    primary {
      component_type
    }
  }
`
