/* eslint-disable camelcase */
import React from 'react'
import styled from '@emotion/styled'
import kebabCase from 'lodash/kebabCase'

import { theme } from '../../styles'
import grayArrowIcon from '../../../static/icons/arrow-icons/gray-left-arrow-icon.svg'
import Text from '../common/Text'
import Space from '../common/Space'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% + 300px);
  margin-left: -150px;
  padding: 80px 0px;
  max-width: 100vw;

  @media (max-width: 1500px) {
    padding: 80px 24px;
  }

  @media (max-width: 1050px) {
    width: calc(100% + 20px);
    max-width: unset;
    margin-left: 0px;
    overflow-x: scroll;
    align-items: flex-start;
    padding: 80px 24px 0px 0px;
    margin-bottom: 80px;
  }
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 1050px) {
    overflow-x: scroll;
    width: max-content;
  }
`

const ColumnHeader = styled.div`
  display: flex;
  justify-content: center;
  flex: 3;
  border: 2px solid ${p => p.theme.color[p.borderColor]};
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  align-items: center;

  @media (max-width: 1050px) {
    max-width: 300px;
  }

  @media (max-width: 800px) {
    max-width: 225px;
  }

  p {
    text-align: left;
  }
`

const RowGroup = styled.div``

const Row = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  border: 1px solid ${theme.color.gray200};
  border-bottom: none;
  background-color: ${p => p.theme.color[p.backgroundColor]};

  &:nth-of-type(1) {
    border-radius: 16px 16px 0px 0px;
  }

  div:first-of-type {
    flex: 2;
    padding-left: 16px;

    @media (max-width: 1050px) {
      max-width: 225px;
    }
  }

  &:last-of-type {
    border-bottom: 1px solid ${theme.color.gray200};
    border-radius: 0px 0px 16px 16px;
  }
`

const HeadingRow = styled(Row)`
  margin-bottom: 24px;
  border-color: transparent;
  padding-left: 0px;

  &:last-of-type {
    border-bottom: none;
  }
`

const TableCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Block = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 3;
  padding: 16px 0px;

  @media (max-width: 1050px) {
    width: 50vw;
    max-width: 300px;
  }

  @media (max-width: 800px) {
    max-width: 225px;
    width: 80vw;
  }
`

const HeadingBlock = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  padding-right: 16px;

  @media (max-width: 1050px) {
    display: none;
  }
`

const MobHeadingBlock = styled.div`
  display: none;

  @media (max-width: 1050px) {
    display: flex;
    margin-bottom: 24px;
  }
`

const ArrowIcon = styled.img`
  margin-left: 16px;
`

const MobileSwipeTextContainer = styled.div`
  display: none;

  @media (max-width: 1050px) {
    max-width: 220px;
    display: flex;
    padding-right: 16px;
    align-items: center;
    justify-content: center;
  }
`

const sections = [
  {
    items: [
      {
        column1: 'Approach',
        column2: 'Only hybrid solution combining automation, expert human testing, and custom remediations',
        column3: 'Primarily automation with limited expert human testing',
        column4: 'Primarily expert human testing with some automation',
      },
      {
        column1: 'Compliance',
        column2: 'Highest level of compliance with WCAG 2.1, 2.2, ADA, Section 508, AODA, and others',
        column3:
          'Can’t be fully compliant due to inability of automation to test and remediate for every WCAG criteria',
        column4: 'Level of compliance depends on how recently an audit took place and whether issues were remediated',
      },
      {
        column1: 'Lawsuit Prevention',
        column2: 'Approximately 80% of alleged claims are invalid under AudioEye’s coverage',
        column3: 'Significant percentage of valid accessibility issues in lawsuits(1)',
        column4: 'Significant percentage of valid accessibility issues in lawsuits(1)',
      },
      {
        column1: 'Legal & Monetary Support',
        column2: 'Custom legal support, and industry best substantive guaranteed offering',
        column3:
          'Varies by vendor (accessiBe offers no guarantee, UserWay offers a “$1 million” guarantee with fine print criteria)',
        column4: 'Typically do not offer any guarantee or warranty',
      },
      {
        column1: 'Automation',
        column2: 'Continuously finds and fixes accessibility issues, including content behind logins or paywalls',
        column3: 'Runs every 24 hours but has limited reach, unable to scan content behind logins or paywalls',
        column4: 'No automation, which results in slower delivery, limited coverage, and more expensive services',
      },
      {
        column1: 'Manual Audits',
        column2: 'In-house certified experts for auditing and remediation',
        column3: 'Limited auditing that is outsourced with no remediation support',
        column4: 'Selective auditing available, but no remediation support',
      },
      {
        column1: 'Depth of Coverage',
        column2: 'Automatic and custom remediations apply to every page a user visits',
        column3: '35% of most pages',
        column4: 'Select pages that were audited',
      },
    ],
  },
]

const Section = ({ title, items }) => (
  <>
    {items.map(
      (
        item,
        i // eslint-disable-next-line react/no-array-index-key
      ) => (
        // eslint-disable-next-line react/no-array-index-key
        <Row role="row" key={`$TITLE-${i}`} backgroundColor={i % 2 ? 'white' : 'gray50'}>
          <Block role="cell" aria-labelledby={`table-header-${kebabCase(title)}`}>
            <Text smallBodyMedium>{item.column1}</Text>
          </Block>
          <Block role="cell" aria-labelledby={`table-header-${kebabCase(title)}`}>
            <Text smallBodyMedium>{item.column2}</Text>
          </Block>
          <Block role="cell" aria-labelledby={`table-header-${kebabCase(title)}`}>
            <Text smallBodyMedium color="gray500">
              {item.column3}
            </Text>
          </Block>
          <Block role="cell" aria-labelledby={`table-header-${kebabCase(title)}`}>
            <Text smallBodyMedium color="gray500">
              {item.column4}
            </Text>
          </Block>
        </Row>
      )
    )}
  </>
)

const CompetitorComparison = () => (
  <Wrapper>
    <TableWrapper role="table">
      <MobHeadingBlock>
        <Text heading3>Comparisons</Text>
      </MobHeadingBlock>
      <div role="rowgroup" style={{ zIndex: '1' }}>
        <HeadingRow role="row">
          <HeadingBlock role="cell">
            <Text heading3>Comparisons</Text>
          </HeadingBlock>
          <MobileSwipeTextContainer>
            <ArrowIcon src={grayArrowIcon} style={{ marginRight: '16px' }} />
            <Text smallBody color="gray700">
              Swipe to see and compare
            </Text>
          </MobileSwipeTextContainer>
          <ColumnHeader borderColor="purple500">
            <Text bodyBold center role="columnheader">
              AudioEye
            </Text>
          </ColumnHeader>
          <ColumnHeader borderColor="gray300">
            <Text bodyBold center role="columnheader">
              Simple Widgets or Plugins (such as accessiBe and UserWay)
            </Text>
          </ColumnHeader>
          <ColumnHeader borderColor="gray300">
            <Text bodyBold center role="columnheader">
              Consultative Auditing (such as Level Access/eSSENTIAL Accessibility)
            </Text>
          </ColumnHeader>
        </HeadingRow>
      </div>
      <TableCard>
        {sections.map(currentSection => (
          <RowGroup role="rowgroup" key={kebabCase(currentSection.column1)}>
            <Section title={currentSection.column1} items={currentSection.items} />
          </RowGroup>
        ))}
      </TableCard>
    </TableWrapper>
    <Space height={40} />
    <Text color="gray500">
      <i>
        (1) Valid lawsuit percentage based on an internal review of sampling of competitor’s customer websites named in
        accessibility litigation performed in November 2023, which showed 67% accessibility issues for each of accessiBe
        and Userway and 66.5% accessibility issues for Level Access/eSSENTIAL Accessibility. Actual results may vary
        depending upon scope and timing of implementation of competitors’ offering. In January 2023, Level Access and
        eSSENTIAL Accessibility announced the completion of a merger.
      </i>
    </Text>
  </Wrapper>
)

export default CompetitorComparison
